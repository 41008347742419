.app {
	display: flex;
}
.granita {
	font-size: 5rem;
	width: 5rem;
	position: relative;
}
.granita-bottom {
	position: absolute;
}
.granita-top {
	filter: grayscale(100%);
	position: absolute;
}
.granita-container {
	display: flex;
	flex-wrap: wrap;
}
